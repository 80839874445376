//LIVE
// const url_configs = {
//     api: 'https://api.meeku.world/api/v1',
//     breakout_room_server: 'https://breakout.meeku.world',
//     chat_server:'https://chat-server.meeku.world',
//     frontend: 'https://meeku.world/',
//     white_board_server: 'https://whiteboard.meeku.world/?username=',
//     freeCourse: '/course-detail/problem-solving-using-robotics-7',
//     paidCourse: '/course-detail/problem-solving-using-robotics-1',
//     stripeKey: 'pk_live_51LmmV7J4eXiIQTD6ogikSQLoVnGZTQcwtPlhMu3en8D6za6clOmSmOJwXIzAgfqBigC2rTMIDhcMi34tRctQoP2x00HAMWUjwz',
//     firebaseConfig: {
//         apiKey: "AIzaSyAlcfMleZl1CWi1GT9lBicBVshkrlegZco",
//         authDomain: "meeku-board.firebaseapp.com",
//         projectId: "meeku-board",
//         storageBucket: "meeku-board.appspot.com",
//         messagingSenderId: "693107932669",
//         appId: "1:693107932669:web:65fb28d667733f9db46d94"
//     },
//     imageDocumentPath: "https://duhz3a6ai07b3.cloudfront.net/"
// };

//UAT
const url_configs = {
    api: 'https://test.api.meeku.world/api/v1',
    breakout_room_server: 'https://test.breakout.meeku.world',
    chat_server: 'https://test.chat-server.meeku.world',
    frontend: 'https://test.admin.meeku.world/',
    white_board_server: 'https://test.whiteboard.meeku.world/?username=',
    // freeCourse: '/course-detail/problem-solving-using-robotics-149',
    freeCourse: '/self-learning-parent-registration',
    paidCourse: '/course-detail/problem-solving-using-robotics-125',
    stripeKey: 'pk_test_51LmmV7J4eXiIQTD6FpmMlxNj8t9hVZnDHru0ouYBDCrJm7v37ZJA7HSKGPnz5ilocm7T4dMGDR7zCCd9iWtPVa1X007ZLzLYif',
    firebaseConfig: {
        apiKey: "AIzaSyBb3utClsRffitJo765wiCYi2xaqIoHPXU",
        authDomain: "test-board-606f6.firebaseapp.com",
        projectId: "test-board-606f6",
        storageBucket: "test-board-606f6.appspot.com",
        messagingSenderId: "845203966009",
        appId: "1:845203966009:web:4edf2185e1d463c6b465e8"
    },
    imageDocumentPath: "https://meeku-uat.s3.amazonaws.com/",
    plans: [
        {
            id: "prod_Q4dQ37mWmzncmr",
            name: "Individual",
            no_of_kids: 1,
            trialing_message_count: 0,
            active_message_count: 100
        },
        {
            id: "prod_Q7ZIY4Gp7QicE7",
            name: "Family",
            no_of_kids: 4,
            trialing_message_count: 0,
            active_message_count: 120
        }],
}

//LOCAL
// const url_configs = {
//     api: 'http://localhost:4000/api/v1',
//     breakout_room_server: 'http://localhost:5003',
//     chat_server: 'http://localhost:5001',
//     frontend: 'http://localhost:3000/',
//     white_board_server: 'http://localhost:3001/?username=',
//     // freeCourse: '/course-detail/problem-solving-using-robotics-5',
//     freeCourse: '/self-learning-parent-registration',
//     paidCourse: '/course-detail/problem-solving-using-robotics-1',
//     stripeKey: 'pk_test_51LmmV7J4eXiIQTD6FpmMlxNj8t9hVZnDHru0ouYBDCrJm7v37ZJA7HSKGPnz5ilocm7T4dMGDR7zCCd9iWtPVa1X007ZLzLYif',
//     firebaseConfig: {
//         apiKey: "AIzaSyBb3utClsRffitJo765wiCYi2xaqIoHPXU",
//         authDomain: "test-board-606f6.firebaseapp.com",
//         projectId: "test-board-606f6",
//         storageBucket: "test-board-606f6.appspot.com",
//         messagingSenderId: "845203966009",
//         appId: "1:845203966009:web:4edf2185e1d463c6b465e8"
//     },
//     imageDocumentPath: "https://meeku-uat.s3.amazonaws.com/",
//     plans: [
//         {
//             id: "prod_Q4dQ37mWmzncmr",
//             name: "Individual",
//             no_of_kids: 1,
//             trialing_message_count: 0,
//             active_message_count: 100
//         },
//         {
//             id: "prod_Q7ZIY4Gp7QicE7",
//             name: "Family",
//             no_of_kids: 4,
//             trialing_message_count: 0,
//             active_message_count: 120
//         }],
// };

export default url_configs;