import React from "react";
import assets from "../../../../config/assets";
import { Container } from "@mui/material";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./courses-description-v4.css";
import { useNavigate } from 'react-router-dom';
import storage_configs from '../../../../config/storage-config'
import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";

export default function CoursesDescriptionV4() {

  const [currentSection, setCurrentSection] = useState('features');

  const [selectedDescriptionDetails, setSelectedDescriptionDetails] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    if (storedData) {
      setSelectedDescriptionDetails(JSON.parse(storedData));
    } else {
      if (getCurrentUserData().role === conditional_configs.role.learner) {
        navigate('/learner/missions');
      } else if (getCurrentUserData().role === conditional_configs.role.parent) {
        navigate('/parent/self-learning');
      }
    }
  }, []);


  const navigate = useNavigate();

  const { gameName } = useParams();

  useEffect(() => {
    const sections = ['learningAspects', 'howToPlay'];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % sections.length;
      setCurrentSection(sections[currentIndex]);
    }, 5000); // Change section every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleBack = () => {
    // Remove selected whiteboard from localStorage
    localStorage.removeItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    // Navigate back to the previous page
    navigate(-1);
  };




  return (
    <div className="courses-description-div-v4">
      <Container>
        <div>
          <p className="courses-description-title-v4">{selectedDescriptionDetails?.name}</p>
        </div>

        <div className="courses-video-container-v4 mt-5 mb-4">
          <div className="video-container-div-cv4">
            <iframe
              src={selectedDescriptionDetails?.media_url}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              width="100%"
              height="400px"
              title="Soccer"
            ></iframe>

          </div>
          <button className="start-game-cgv4 back-btn-des-cgv4" onClick={() => handleBack()}>
            <p className="mb-0 me-2 ">back</p>
            <img className="" width={40} src={assets.homev4.white_with_blue_arrow}></img>
          </button>
        </div>

        {/* Conditionally render description section */}
        {selectedDescriptionDetails?.description && (
          <div className="pt-5">
            <p className="cour-description-text-v4">DESCRIPTION</p>
            <div
              className="cour-des-text-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.description }}
            />
          </div>
        )}

        {/* Conditionally render overview section */}
        {currentSection === 'learningAspects' && selectedDescriptionDetails?.overview && (
          <div className="cour-features-div-v4 mt-5">
            <p className="cour-features-text-v4">Learning Aspects</p>
            <div
              className="cour-features-des-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.overview }}
            />
          </div>
        )}

        {currentSection === 'howToPlay' && selectedDescriptionDetails?.instructions && (
          <div className="cour-features-div-v4 mt-5">
            <p className="cour-features-text-v4">Play</p>
            <div
              className="cour-features-des-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.instructions }}
            />
          </div>
        )}
      </Container>

    </div>
  );
}
